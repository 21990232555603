import React, { useState, useEffect } from 'react';
import { Transition, CSSTransition, TransitionGroup } from 'react-transition-group';
import RepairCard from './cartrem';
import '../styles/column.css'

function Column({ title, items, cardsPerPage }) {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(items.length / cardsPerPage);
  const changePage = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextPage = (currentPage + 1) % totalPages;
      setCurrentPage(nextPage);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentPage, totalPages]);

  return (
    <div className='flex min-h-full flex-1 overflow-auto flex-col bg-slate-900 p-2 rounded mb-8 overflow-y-auto
    [&::-webkit-scrollbar]:w-2
    [&::-webkit-scrollbar-track]:rounded-sm
    [&::-webkit-scrollbar-track]:bg-slate-900
    [&::-webkit-scrollbar-thumb]:rounded-sm
    [&::-webkit-scrollbar-thumb]:bg-slate-400'>
      <div className="flex w-full justify-between items-center border-b-2 border-silver mb-4 p-1">
        <h3 className='text-lg'>{title}</h3>
        <div className='flex items-center bg-blue-900 rounded w-8 h-8 text-white justify-center'>
          <AnimatedNumber value={items.length}></AnimatedNumber>
        </div>
      </div>
      {Array.from({ length: totalPages }).map((_, page) => (
        <div className={`page ${page === currentPage && 'active'}`}>
          {items.slice(page * cardsPerPage, (page + 1) * cardsPerPage).map((item) => {
            return (
              <RepairCard key={item.numberremonta} data={item} />
            );
          })}
        </div>
      ))}
      {
        totalPages > 1 && ( // Проверка, есть ли более одной страницы
          <div className='flex relative bottom-0 left-0 right-0 max-h-8 justify-center items-center content-center'>
            {/* Render page buttons */}
            {Array.from({ length: totalPages }).map((_, page) => (
              <button
                key={page}
                className={`w-8 h-8 text-white bg-slate-900 border border-blue-800 ${page === currentPage && 'active'}`}
                onClick={() => changePage(page)}
              >
                {page + 1}
              </button>
            ))}
          </div>)
      }
    </div >
  );
}

function AnimatedNumber({ value }) {
  const [displayedValue, setDisplayedValue] = useState(value);

  useEffect(() => {
    // Animate the number from the current value to the new value
    const animationInterval = setInterval(() => {
      if (displayedValue !== value) {
        if (displayedValue < value) {
          setDisplayedValue(displayedValue + 1);
        } else if (displayedValue > value) {
          setDisplayedValue(displayedValue - 1);
        }
      } else {
        clearInterval(animationInterval);
      }
    }, 100); // Adjust the interval for the animation speed

    // Clear the interval when the component unmounts or when the value changes
    return () => {
      clearInterval(animationInterval);
    };
  }, [value, displayedValue]);

  return (
    <div className="text-white">
      {displayedValue}
    </div>
  );
}

export default Column;