import React from 'react';

function checkDate(date) {
  const now = new Date();
  const dateString = date;
  const [day, month, year] = dateString.split('.').map(Number);
  const carddate = new Date(year, month - 1, day);
  const diff = Math.abs(carddate - now);

  if (diff >= 2419200000) {
    return 'bg-red-700 bg-opacity-50 animate-pulse';
  } else if (diff >= 604800000) {
    return 'bg-yellow-500 bg-opacity-50 animate-pulse';
  } else if (diff >= 264785812) {
    return 'bg-green-500 bg-opacity-50 animate-pulse';
  }
  return 'bg-green-500 bg-opacity-30';
}

function checkDateOr(datapr, dataizm) {
  let dateCN = '';
  console.log(dataizm);
  if (dataizm != null && dataizm !== '') {
    dateCN = dataizm; // Assign the value if it exists
  } else  {
    dateCN = datapr; // Assign the value if it exists
  }
  
  // Assuming checkDate is a function that exists elsewhere in your code
  const dateClassName = checkDate(dateCN);
  console.log(dateCN);
  return dateClassName;
}

function RepairCard({ data }) {
  const dateClassName = checkDateOr(data.datapriemki, data.dataizmst)

  return (
    <div className={`relative mb-2 rounded-md shadow transition-transform ease-in-out duration-300 bg-slate-700`}>
      <div className={`flex flex-col gap-0 bg-blue-700 bg-opacity-75 text-white p-1 rounded-md ${dateClassName}`}>
        <p className='font-medium text-lg'>{data.numberremonta.slice(5).replace(/^0+/, '')} от {data.datapriemki}</p>
        <p className='font-normal text-sm'>Изменен {data.dataizmst}</p>
      </div>
      
      <p className='font-normal text-sm p-1'>{data.nomenklatura}</p>
      <p className='font-normal text-sm line-clamp-2 p-1'>{data.kontr}</p>
    </div>
  );
}

export default RepairCard;