import React from 'react';

function Footer() {
    return (
        <footer className='flex relative h-full w-full p-2 text-slate-50 justify-center text-center items-center flex-row bg-slate-900'>
            <p className='version'>Версия: {process.env.REACT_APP_APP_VERSION}</p>
            <p className='author'></p>
        </footer>
    )
}

export default Footer;