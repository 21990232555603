import React, { useState, useEffect } from 'react';
//import './App.css';
import Header from './modules/header.js';
import Footer from './modules/footer';
import Column from './modules/column';
import io from 'socket.io-client';

function App() {
  const [data, setData] = useState({
    prinyats: [],
    zipoks: [],
    zipneeds: [],
    soglasovats: [],
    soglasovanos: [],
    opovestitos: [],
    zipokyes: [],
    kvid: [],
  });

  const [displayColumns, setDisplayColumns] = useState({
    prinyats: true,
    zipoks: true,
    zipneeds: true,
    soglasovats: true,
    soglasovanos: true,
    opovestitos: true,
    zipokyes: true,
    kvid: true,
  });

  const [cardCount, setCardCount] = useState(8);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_ADR_BAKEND, { transports: ['websocket'] });
    socket.on('connect', () => {
      // Отправляем событие для регистрации как табло
      socket.emit('register_tablo');
    });

    socket.on('prinyats', (data) => updateData('prinyats', data));
    socket.on('zipoks', (data) => updateData('zipoks', data));
    socket.on('zipneeds', (data) => updateData('zipneeds', data));
    socket.on('soglasovats', (data) => updateData('soglasovats', data));
    socket.on('soglasovanos', (data) => updateData('soglasovanos', data));
    socket.on('opovestitos', (data) => updateData('opovestitos', data));
    socket.on('zipokyes', (data) => updateData('zipokyes', data));
    socket.on('kvid', (data) => updateData('kvid', data));

    return () => {
      socket.disconnect();
    };
  }, []);

  const updateData = (key, newData) => {
    setData((prevData) => ({
      ...prevData,
      [key]: newData,
    }));
  };

  const handleColumnDisplayChange = (columnName) => {
    setDisplayColumns((prevColumns) => ({
      ...prevColumns,
      [columnName]: !prevColumns[columnName],
    }));
  };

  const handleCardCountChange = (newCardCount) => {
    setCardCount(newCardCount);
  };

  return (
    <div className='flex relative text-center w-screen h-screen overflow-hidden min-h-screen box-border flex-col'>
      <Header
        displayColumns={displayColumns}
        cardCount={cardCount}
        setCardCount={setCardCount}
        onColumnDisplayChange={handleColumnDisplayChange}
        onCardCountChange={handleCardCountChange}
      />
      <div className='min-h-[calc(100%-6rem)] bg-slate-700 text-white flex relative p-2 justify-between gap-2 flex-row'>
        {displayColumns.prinyats && (
          <Column key='1' title="Принят в ремонт" items={data.prinyats} cardsPerPage={cardCount} />
        )}
        {displayColumns.zipneeds && (
          <Column key='2' title="Нужен ЗИП" items={data.zipneeds} cardsPerPage={cardCount} />
        )}
        {displayColumns.soglasovats && (
          <Column key='3' title="Согласовать" items={data.soglasovats} cardsPerPage={cardCount} />
        )}
        {displayColumns.soglasovanos && (
          <Column key='4' title="Согласовано" items={data.soglasovanos} cardsPerPage={cardCount} />
        )}
        {displayColumns.zipoks && (
          <Column key='5' title="Ожидает ЗИП" items={data.zipoks} cardsPerPage={cardCount} />
        )}
        {displayColumns.zipokyes && (
          <Column key='6' title="ЗИП Получен" items={data.zipokyes} cardsPerPage={cardCount} />
        )}
        {displayColumns.opovestitos && (
          <Column key='7' title="Оповестить клиента" items={data.opovestitos} cardsPerPage={cardCount} />
        )}
        {displayColumns.kvid && (
          <Column key='8' title="К Выдаче" items={data.kvid} cardsPerPage={cardCount} />
        )}
      </div>
      <div className='flex relative bottom-0 left-0 right-0 max-h-8 justify-center items-center content-center'>
        <Footer />
      </div>
    </div>
  );
}

export default App;