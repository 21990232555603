import React, { useState, useEffect, useRef } from 'react';
import SettingIco from '../icons/settings.svg';
import { CSSTransition } from 'react-transition-group';
import '../styles/settingsMenu.css';

function SettingsMenu({ displayColumns, cardCount, setCardCount, onColumnDisplayChange, onCardCountChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleColumnDisplayChange = (columnName) => {
    onColumnDisplayChange(columnName);
  };

  const handleCardCountChange = (event) => {
    const newCardCount = parseInt(event.target.value, 10);
    onCardCountChange(newCardCount);
  };

  const handleIncrement = () => {
    setCardCount(cardCount + 1);
  };

  const handleDecrement = () => {
    if (cardCount > 1) {
      setCardCount(cardCount - 1);
    }
  };

  useEffect(() => {
    // Функция-обработчик для закрытия меню при клике вне меню
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Добавляем обработчик события click к документу при открытии меню
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    // Убираем обработчик при размонтировании компонента
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="flex relative left-0 w-[300px] text-xl ml-5 bg-slate-900" ref={menuRef}>
    <button className={`settings-button ${isOpen ? 'menu-active' : ''}`} onClick={toggleMenu}>
      <div className='iconback'>
        <img src={SettingIco} alt='Settings' className='icon' />
      </div>
      <span className={`button-text ${isOpen ? 'visible' : ''}`}>Настройки</span>
    </button>

    <CSSTransition
      in={isOpen}
      timeout={300} // Продолжительность анимации в миллисекундах
      classNames={{
        enter: 'menu-content-enter',
        enterActive: 'menu-content-enter-active',
        exit: 'menu-content-exit',
        exitActive: 'menu-content-exit-active',
      }}
      unmountOnExit
    >
        <div className="menu-content">
          <h3>Выберите столбцы:</h3>
          <label className="switch">
            <input
              type="checkbox"
              key="prinyats"
              checked={displayColumns.prinyats}
              onChange={() => handleColumnDisplayChange('prinyats')}
            />
            <span className="slider"></span>
              Принят в ремонт
          </label>
          <label className="switch">
            <input
              type="checkbox"
              key="zipneeds"
              checked={displayColumns.zipneeds}
              onChange={() => handleColumnDisplayChange('zipneeds')}
            />
            <span className="slider"></span>
            Заказать ЗИП
          </label>
          <label className="switch">
            <input
              type="checkbox"
              key="soglasovats"
              checked={displayColumns.soglasovats}
              onChange={() => handleColumnDisplayChange('soglasovats')}
            />
            <span className="slider"></span>
            Согласовать
          </label>
          <label className="switch">
            <input
              type="checkbox"
              key="soglasovanos"
              checked={displayColumns.soglasovanos}
              onChange={() => handleColumnDisplayChange('soglasovanos')}
            />
            <span className="slider"></span>
            Согласовано
          </label>
          <label className="switch">
            <input
              type="checkbox"
              key="zipoks"
              checked={displayColumns.zipoks}
              onChange={() => handleColumnDisplayChange('zipoks')}
            />
            <span className="slider"></span>
            Ожидает ЗИП
          </label>
          <label className="switch">
            <input
              type="checkbox"
              key="zipokyes"
              checked={displayColumns.zipokyes}
              onChange={() => handleColumnDisplayChange('zipokyes')}
            />
            <span className="slider"></span>
            ЗИП Получен
          </label>
          <label className="switch">
            <input
              type="checkbox"
              key="opovestitos"
              checked={displayColumns.opovestitos}
              onChange={() => handleColumnDisplayChange('opovestitos')}
            />
            <span className="slider"></span>
            Оповестить клиента
          </label>
          <label className="switch">
            <input
              type="checkbox"
              key="kvid"
              checked={displayColumns.kvid}
              onChange={() => handleColumnDisplayChange('kvid')}
            />
            <span className="slider"></span>
            К Выдаче
          </label>
          <h3>Настройки карточке:</h3>
          <label>
            Количество карточек на странице:{' '}
            <div className='cardint'>
            <input
    type='number'
    value={cardCount}
    onChange={handleCardCountChange}
    className="styled-input-number" 
  />
  <div className='buttcard'>
  <button
    className="styled-number-buttons-top"
    onClick={() => handleIncrement()} 
  >
    +
  </button>
  <button
    className="styled-number-buttons-bottom"
    onClick={() => handleDecrement()} 
  >
    -
  </button>
  </div>
  </div>
          </label>
        </div>
      </CSSTransition>
    </div>
  );
}

export default SettingsMenu;