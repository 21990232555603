import React from 'react';
import Logo from '../icons/icon.png';
import SettingsMenu from './SettingsMenu'; // Импортируйте новый компонент

function Header({ displayColumns, cardCount,setCardCount, onColumnDisplayChange, onCardCountChange }) {
  return (
    <header  className='flex relative bg-slate-900 h-16 w-full flex-row items-center justify-between text-white border-b-2 border-silver'>
      <SettingsMenu 
        displayColumns={displayColumns}
        cardCount={cardCount}
        setCardCount={setCardCount}
        onColumnDisplayChange={onColumnDisplayChange}
        onCardCountChange={onCardCountChange}
      />
      <img src={Logo} className='flex relative items-center h-10 w-10 pointer-events-none rounded-[50%] ml-auto bg-slate-50' alt='logo' />
      <div className='flex items-center ml-7 pr-7 mr-auto flex-row'>
        <p className='text-[calc(10px+2vmin)]'>Доска заказов</p>
      </div>
      {/* Используйте новый компонент SettingsMenu */}
      
    </header>
  );
}

export default Header;